<!-- 大乐透中奖计算 -->
<template>
    <div class="">
      <dltfu :fu3="ssq"></dltfu>
      <div class="clear"></div>
    </div>
  </template>
  
  <script>
  //import '../js/mpublic.js' //js文件引入，注意路径
  import dltfu from "@/components/jin/fu.vue";
  //import api from '@/js/method'
  export default {
    name: "",
    components: {
      dltfu,
    },
    data() {
      // 数据源
      return {
         ssq: [{"wanfa":'dlt',"xh":"前区","xl":"后区","zh":"中前区","zl":"中后区",'xd':'前区胆','xt':'前区拖','zd':'中前胆','zt':'中前拖','xtl2':'后区拖','ztl2':'中后拖','xtl':'后区胆','ztl':'中后胆','pic':require('@/assets/jpg/dlt.jpg')}],
      };//xuan, xtl, dl, dlt, xd, xt, xl, zd, zt, zl(dl=0后区未中，dl=1后区中一个，dl=2后区中两个)（xl选后区胆码个数 0代表没选后区胆码，1代码选一个后区胆码，zl代表后区胆码中未中，0代表未中，1代表中）
    },
    mounted() {},
    computed: {},
    methods: {
      // 函数体部分，js的主要逻辑控制
    
    },
    props: {},
  };
  </script>
  
  
  <style scoped>
  /* @import './swiper.css'; */
  
  .clear {
    clear: both;
  }
  </style>